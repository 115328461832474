"use strict";

import menu from "./modules/menu";
import toggleFeedback from "./modules/toggleFeedback";
import validate from "./modules/validate";
import send from "./modules/send";

window.addEventListener("DOMContentLoaded", () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    SmoothScroll({
        // Время скролла 400 = 0.4 секунды
        animationTime: 700,
        // Размер шага в пикселях
        stepSize: 80,

        // Дополнительные настройки:

        // Ускорение
        accelerationDelta: 20,
        // Максимальное ускорение
        accelerationMax: 1,

        // Поддержка клавиатуры
        keyboardSupport: true,
        // Шаг скролла стрелками на клавиатуре в пикселях
        arrowScroll: 50,

        // Pulse (less tweakable)
        // ratio of "tail" to "acceleration"
        pulseAlgorithm: true,
        pulseScale: 4,
        pulseNormalize: 1,

        // Поддержка тачпада
        touchpadSupport: true,
    });

    function anchorLink(e) {
        let evt = e ? e : window.event;
        (evt.preventDefault) ? evt.preventDefault() : evt.returnValue = false;
        let domenLocation = location.origin;
        let siteLocation = location.href.slice(0, -1);
        if (domenLocation === siteLocation) {

            gsap.to(window, {scrollTo: ".works", duration: 1, ease: "power2.out"});
        } else {
            location.href = location.origin + "/#work";
        }
    }

    const casesLink = document.querySelector('.header__item:first-child a');
    const footerLink = document.querySelector('.footer__menu-item:first-child a');

    casesLink.addEventListener('click', (e) => anchorLink(e));
    footerLink.addEventListener('click', (e) => anchorLink(e));

    validate('.form .form__btn', '.form input[name="name"]', '.form input[name="phone"]', '.form input[name="email"]');
    validate('.feedback .feedback__btn', '.feedback input[name="name"]', '.feedback input[name="phone"]', '.feedback input[name="email"]');
    send("#form", '.form__btn', '.responce', '.form__close', ".form__form", '.form__wrapper');
    send("#feedback", '.feedback__btn', '.feedback__response', '.feedback__close', ".feedback__form", ".feedback");
    menu();

    // toggleFeedback(contactBtn);
    // toggleFeedback(footerBtn)

    const preloaderPathLength = document.querySelector(".preloader__svg path").getTotalLength().toFixed();
    gsap.set(".preloader__svg path", {
        strokeDasharray: `${preloaderPathLength/2}, ${preloaderPathLength}`,
        strokeDashoffset: preloaderPathLength/2,
        opacity: 1
    })
    const preloaderAnimate = gsap.to(".preloader__svg path", {
        strokeDashoffset: -preloaderPathLength,
        duration: 3.5,
        ease: "power1.inOut",
        repeat: -1,
        onRepeat: () => {
            if (document.querySelector('.preloader').classList.contains('hidden')) {
                preloaderAnimate.kill();
            }
        }
    })
});


// gsap.to('.preloader', {
//   duration: 1,
//   autoAlpha: 0,
//   onComplete: () => {
//   }
// })
