const toggleFeedback = (btn) => {

    const body = document.querySelector('html');
    const form = document.querySelector('.feedback');
    const close = document.querySelector('.feedback__close');
    const feedback = document.querySelector('.feedback__form');
    const overlay = document.querySelector('.overlay');

    btn.addEventListener('click', () => {
        body.classList.add('lock');
        form.classList.add('visible');
        overlay.classList.add('visible');
    });

    function closePopup(trigger) {
        trigger.addEventListener('click', () => {
            body.classList.remove('lock');
            form.classList.remove('visible')
            feedback.reset();
            overlay.classList.remove('visible');
        })
    }
    closePopup(close);
    closePopup(overlay)

}

module.exports = toggleFeedback;