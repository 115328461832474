const menu = () => {
    const burgerBtn = document.querySelector('.header__hamburger');
    const body = document.querySelector('html');
    const letsTalk = document.querySelector('.header__btn');
    const contactBtn = document.querySelector('.contact-us__btn');
    const footerBtn = document.querySelector('.footer__btn');
    const closeForm = document.querySelector('.form__close');

    let mm = gsap.matchMedia();
    mm.add({
        isMobile: "(max-width: 768px)",
        isDesktop: "(min-width: 769px)"
    }, (context) => {
        let { isMobile, isDesktop } = context.conditions;

        // let toggleMenu = gsap.to('.header__wrapper', {
        //     height: `95dvh`,
        //     paused: true,
        //     autoAlpha: 1,
        //     paddingBottom: isMobile ? 100 : 80,
        //     duration: .3,
        //     ease: 'power1.out'
        // })

        const tlShowLine = gsap.timeline({
            defaults: {
                duration: 5,
                delay: 0.6,
                ease: "power4.out"
            },
        });

        burgerBtn.addEventListener('click', () => {
          burgerBtn.classList.contains('opened') ? closeMenu() : openMenu(); 
        });

        function openMenu() {
            burgerBtn.classList.add('opened')
            body.classList.add('lock')
            gsap.to('.header__wrapper', {
                height: window.innerHeight,
                autoAlpha: 1,
                paddingBottom: isMobile ? 100 : 80,
                duration: .3,
                ease: 'power1.out'
            })
        }

        function closeMenu() {
            burgerBtn.classList.remove('opened')
            body.classList.remove('lock');
            gsap.to('.header__wrapper', {
                height: 0,
                autoAlpha: 0,
                paddingBottom: 0,
                duration: .3,
                ease: 'power1.out'
            })
        }

        function toggleBlocks(time1, time2, left, className) {
            let toggleForm = gsap.to('.form', {
                translateX: left,
                paused: true,
                duration: .3,
                delay: time1,
                ease: 'power1.out'
            })
            let toggleLeft = gsap.to('.form__left', {
                translateX: left,
                paused: true,
                delay: time2,
                duration: .3,
                ease: 'power1.out'
            })
            toggleForm.play()
            isMobile ? null : toggleLeft.play();
            if (className) {
                body.classList.add('lock')
            } else {
                body.classList.remove('lock')
            }
        }


        contactBtn.addEventListener('click', () => {
            toggleBlocks(0, 0.3, 0, true);
            tlShowLine.to(".form__header svg path", { strokeDashoffset: 0, autoAlpha: 1 });
        });
        footerBtn.addEventListener('click', () => {
            toggleBlocks(0, 0.3, 0, true);
            tlShowLine.to(".form__header svg path", { strokeDashoffset: 0, autoAlpha: 1 });
        });
        letsTalk.addEventListener('click', () => {
            toggleBlocks(0, 0.3, 0, true);
            tlShowLine.to(".form__header svg path", { strokeDashoffset: 0, autoAlpha: 1 });
        });
        closeForm.addEventListener('click', () => {
            isMobile ? (toggleBlocks(0, 0, '100%', false)) : (toggleBlocks(0.3, 0, '100%', false));
            tlShowLine.to(".form__header svg path", { strokeDashoffset: 3516, autoAlpha: 0 });
        })
    })





}
module.exports = menu;
