
const send = (formId, formBtn, responce, close, formBody, inner) => {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    mm.add({
        isMobile: "(max-width: 768px)",
        isDesktop: "(min-width: 769px)"
    }, (context) => {
        let { isMobile, isDesktop } = context.conditions;
        let form = document.querySelector(formId);
        const formWrapper = document.querySelector(formBody);
        const serverResponce = document.querySelector(responce);
        const closeBtn = document.querySelector(close);
        const send = document.querySelector(formBtn);


        form.addEventListener("submit", async function (event) {
            event.preventDefault();
            let response = await fetch("/mailer/mail.php", {
                method: "POST",
                body: new FormData(form),
            });

            if (response.ok) {
                console.log('jo')
                serverResponce.classList.add('visible');
                form.classList.add('visible')
                isMobile ? (gsap.to(formWrapper, { duration: .3, ease: "power2.out" }) && gsap.to(inner, { duration: 1, scrollTo: close })) : null;
            } else {
                console.log('fail')
                serverResponce.classList.add('visible');
                form.classList.add('visible')
                isMobile ? (gsap.to(formWrapper, { duration: .3, ease: "power2.out" }) && gsap.to(inner, { duration: 1, scrollTo: close })) : null;
            }
            form.reset();
        });


        closeBtn.addEventListener("click", (e) => {
            // if (formId === "#feedback") {
            //     document.querySelector('.feedback').classList.remove('visible')
            //     document.querySelector('html').classList.remove('lock')
            //     formWrapper.classList.remove('visible');
            //     serverResponce.classList.remove("visible");
            //     send.setAttribute('disabled', '');
            // } else {
            formWrapper.classList.remove('visible');
            serverResponce.classList.remove("visible");
            send.setAttribute('disabled', '');
            const closeMenu = document.querySelector('.header__hamburger .close');
            // closeMenu.click()
        });
    })

}

module.exports = send;